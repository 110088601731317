<template>
  <div class="doctorAddEdit info-page">

    <div class="content" style="overflow-y: auto;">

      <div class="container pd-30 form">

        <el-form ref="form" :rules="rules" :model="form" label-width="110px">

          <el-form-item label="姓名" prop="username">

            <el-input v-model="form.username" placeholder="请输入医生姓名" maxlength="12" :disabled="formStauts"></el-input>

          </el-form-item>

          <el-form-item label="头像" prop="headimg">

            <uoloadSingleImg v-model="form.headimg" ref="uoloadSingleImg" :uploafType="1" :uploadSize="2" :uoloadSingleStatus='uoloadSingleStatus' :form="headImg" :uploadUrl="headimgUrl"
              :fileList="fileHead" @childByValue="childByValue" :tip="headTip"></uoloadSingleImg>

          </el-form-item>

          <el-form-item label="性别" prop="sex">

            <el-radio-group v-model="form.sex" :disabled="formStauts">

              <el-radio label="1">男</el-radio>

              <el-radio label="2">女</el-radio>

            </el-radio-group>

          </el-form-item>

          <el-form-item label="手机号码" prop="telphone">

            <el-input v-model="form.telphone" placeholder="请输入手机号码" :disabled="formStauts"></el-input>

          </el-form-item>

          <el-form-item label="身份证号" prop="card">

            <el-input v-model="form.card" placeholder="请输入身份证号" :disabled="formStauts"></el-input>

          </el-form-item>

          <el-form-item label="所属医院" prop="hospital_id">
            <el-select v-model="form.hospital_id" clearable placeholder="请选择" style="width:70%;">
              <el-option v-for="(item,index) in dlist" :key="index" :label="item.hospital_name" :value="item.hospital_id"></el-option>
            </el-select>
            <!-- 待审核时需显示医生端申请的医院名称 -->
            <span v-if="from == 'doctorManageD'" class="ml-10">{{hospital_name}}</span>
          </el-form-item>

          <el-form-item label="职称" prop="position_id">

            <el-select v-model="form.position_id" placeholder="请选择职称" multiple clearable :disabled="formStauts">

              <el-option v-for="(item,index) in tags " :label="item.position_name" :value="item.position_id" :key="index"></el-option>

            </el-select>

            <upload class="mt-20" v-model="form.position_file" v-on:childByValue="childByValueMore" :fileName="fileZcName" :imgList='fileZC'></upload>

          </el-form-item>

          

          <el-form-item label="所属分类" prop="classify">

            <el-select v-model="form.classify" placeholder="请选择分类" multiple clearable :disabled="formStauts">

              <el-option v-for="(item,index) in clist " :label="item.title" :value="item.value" :key="index"></el-option>

            </el-select>

          </el-form-item>

          <!-- <el-form-item label="负责区域" prop="area" v-if="formStauts==false">

            <el-select v-model="form.area" clearable multiple placeholder="请选择" style="width:70%;" @change="areaChange">

              <el-option v-for="(item,index) in areaList" :key="index" :label="item.area_name" :value="item.area_id"></el-option>

            </el-select>

          </el-form-item> -->

          <el-form-item label="个人简介" prop="introduce">
    
                        <el-input type="textarea" v-model="form.introduce" placeholder="请输入医生个人简介（30-1000）" maxlength="1000" minlength="30" :disabled="formStauts"></el-input>
    
                    </el-form-item>

          <el-form-item label="擅长方向" prop="good_at">

            <el-input type="textarea" v-model="form.good_at" placeholder="请输入医生擅长描述（30-1000）" maxlength="1000" minlength="30" :disabled="formStauts"></el-input>

          </el-form-item>

          <el-form-item label="医师执业资格证">

            <!-- prop="cert_num" 2019.10.15不校验-->

            <el-input type="textarea" v-model="form.cert_num" placeholder="请填写您医师职业资格证号" maxlength="50" :disabled="formStauts"></el-input>

            <upload class="mt-20" v-model="form.cert_front" v-on:childByValue="childByValueMore" :fileName="fileZsName" :imgList='fileZS'></upload>

          </el-form-item>

          <el-form-item label="身份证照片">

            <uoloadSingleImg v-model="form.card_front" ref="uoloadSingleImg" :form="cardFront" :uploafType="4" :uploadSize="4" :uoloadSingleStatus='uoloadSingleStatus' :uploadUrl="cardUrl"
              :fileList="fileCardZ" @childByValue="childByValue" :tip="tip"></uoloadSingleImg>

            <uoloadSingleImg v-model="form.card_reverse" ref="uoloadSingleImg" :form="cardSide" :uploafType="5" :uploadSize="5" :uoloadSingleStatus='uoloadSingleStatus' :uploadUrl="cardUrl"
              :fileList="fileCardF" @childByValue="childByValue" :tip="tip"></uoloadSingleImg>

          </el-form-item>

        </el-form>

        <!-- 待审核显示 -->

        <el-row v-if="from == 'doctorManageD'">

          <el-col style="margin-left:110px;" v-if="statue == '正常'">

            <el-button type="success" @click="checkAgreed(1)">审核通过</el-button>

            <el-button plain @click="checkAgreed(2)">审核不通过</el-button>

          </el-col>

          <el-col style="margin-left:110px;" class="mt-20">

            <div class="his-list">

              <div class="top i-block c99 font-s14 mr-20">审核历史:</div>

              <div class="i-block" v-if="hisList.length > 0">

                <div class="top mb-10" v-for="(item,index) in hisList" :key="index">

                  <div class="c66 font-s14">{{item.message}}</div>

                  <span class="c99 font-s14">{{item.addtime}}</span>

                </div>

              </div>

              <div class="i-block" v-else>

                <div class="top mb-10">

                  <div class="c99 font-s14">暂无审核历史</div>

                  <span class="cff font-s14">暂无审核历史</span>

                </div>

              </div>

            </div>

          </el-col>

        </el-row>

        <el-row v-if="from != 'doctorManageD'">

          <el-col style="margin-left:110px;">

            <el-button type="success" @click="submitInfo('form')" :loading="loading">{{loading ? '保存中' : '保存'}}</el-button>

          </el-col>

        </el-row>

      </div>

    </div>

    <!-- 弹框  start -->

    <el-dialog title="提示" :visible.sync="dialogVisible" width="480px" top="20%">

      <div style="padding:30px 60px 0">

        <div class="p-b-10 font-s14 c66">审核不通过原因：</div>

        <el-input type="textarea" rows="5" v-model="refuseContent" maxlength="20" class="mb-20" placeholder="请输入审核不通过原因，20字以内"></el-input>

      </div>

      <!-- 按钮 -->

      <div class="account-footer clearfix">

        <div>

          <el-button @click="dialogVisible = false">取 消</el-button>

        </div>

        <div>

          <el-button type="primary" @click="refundTrader">确 定</el-button>

        </div>

      </div>

    </el-dialog>

  </div>
</template>
<script>
import uoloadSingleImg from "../../components/uploadSingleImg";
import upload from "../../components/upload";
import { PositionList } from "../../api/api";
import { AddDoctor } from "../../api/api";
import { UpdateDoctorInfo } from "../../api/api";
import { DoctorInfo } from "../../api/api";
import { UploadHeadImage } from "../../api/api";
import { UploadPosition } from "../../api/api";
import { UploadCert } from "../../api/api";
import { UploadCard } from "../../api/api";
import { HospitalListD } from "../../api/api";
import { PermanentAddress } from "../../api/api";
import { DoctorCheckList } from "../../api/api";
import { UpdateHospital } from "../../api/api";
import { get } from "http";
import { constants } from "crypto";
import { rename } from "fs";
export default {
  components: {
    uoloadSingleImg,
    upload,
  },
  data() {
    var checkChineseLetter = (rule, value, callback, text, length) => {
      if (rule.fullField == "username") {
        var texts = "姓名";
      } else if (rule.fullField == "cert_num") {
        var texts = "医师资格证";
      }
      this.common.Validate.checkChineseLetter(
        rule,
        value,
        callback,
        texts,
        length
      );
    };
    var checkTelphone = (rule, value, callback, text) => {
      this.common.Validate.checkTelphone(rule, value, callback, "手机号");
    };
    var checkIDNumber = (rule, value, callback) => {
      this.common.Validate.checkIDNumber(rule, value, callback);
    };
    var checkName2 = (rule, value, callback, text) => {
      this.common.Validate.checkName2(rule, value, callback, "姓名");
    };
    var checkPositionId = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择职称"));
      } else {
        if (this.fileZC.length <= 0) {
          callback(new Error("请选择职称证件图片"));
        } else {
          callback();
        }
      }
    };
    return {
      uid: this.$route.query.doctor_user_id,
      did: this.$route.query.doctor_info_id,
      from: this.$route.query.from,
      statue: this.$route.query.dstatus, //只有待审核有此字段
      isSave: false,
      /**
       * 表单类
       */
      form: {
        username: "",
        headimg: "",
        sex: "",
        telphone: "",
        card: "",
        hospital_id: "",
        position_id: [], //职称id
        classify: [], //所属分类
        position_file: "",
        // introduce: "",
        good_at: "",
        instructions:
          "您支付成功后将自动开启与医生的对话，由于医生自身工作属性可能无法及时响应您的咨询，请耐心等待。",
        cert_num: "",
        cert_front: "",
        card_front: "",
        card_reverse: "",
        area: [],
      },
      classify: [],
      position_id: [],
      hospital_name: "",
      tags: [],
      clist: [
        { title: '在线门诊', value: 1 },
        { title: '心理咨询', value: 2 }
      ], //所属分类列表
      dlist: [], //医院列表
      areaList: [], //负责区域
      loading: false,
      /**
       * 默认传给子组件的属性
       *
       */
      headImg: "上传头像",
      cardFront: "身份证正面",
      cardSide: "身份证反面",
      fileText: "上传证件",
      fileHead: [], //头像数组
      headTip: "请上传免冠正面照，请保持1:1的长宽比，2M以内",
      fileZC: [], //职称数组
      fileZcName: "zc",
      fileZS: [], //证书数组
      fileZsName: "zs",
      fileCardZ: [], //身份证正面
      fileCardF: [], //身份证反面
      tip: "请上传拍摄清晰的证件信息",
      danger: false,
      uoloadSingleStatus: "", //当前状态 是编辑还是新增
      headimgUrl: "", //上传头像地址
      positionUrl: "", //职称附件地址
      certUrl: "", //执业资格证件地址
      cardUrl: "", //上传身份证地址
      /**
       * 校验表单
       */
      rules: {
        username: [
          {
            //姓名
            required: true,
            trigger: "blur",
            validator: checkChineseLetter,
          },
        ],
        headimg: [
          {
            required: true,
            message: "请上传头像",
          },
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
          },
        ],
        telphone: [
          {
            required: true,
            trigger: "blur",
            validator: checkTelphone,
          },
        ],
        card: [
          {
            required: false,
            trigger: "blur",
            validator: checkIDNumber,
          },
        ],
        hospital_id: [
          {
            required: true,
            trigger: "blur",
            message: "请选择医院",
          },
        ],
        position_id: [
          {
            required: true,
            trigger: blur,
            validator: checkPositionId,
          },
        ],
        classify: [
          {
            required: true,
            trigger: "blur",
            message: "请选择所属分类",
          },
        ],
        introduce: [
          {
            required: true,
            trigger: "blur",
            message: "请输入个人简介至少30字",
          },
        ],
        good_at: [
          {
            required: true,
            trigger: "blur",
            message: "请输入擅长描述至少30字",
          },
        ],
        cert_num: [
          {
            required: false,
            trigger: "blur",
            validator: checkChineseLetter,
          },
        ],
      },
      dialogVisible: false, //审核不通过弹框
      refuseContent: "", //审核不通过文字
      hisList: [], //审核历史列表
      formStauts: false, //表单只读
    };
  },
  created() {
    this.getTags();
    this.getHosName();
    this.getAreaList();
    this.headimgUrl = UploadHeadImage;
    this.cardUrl = UploadCard;
    if (this.uid != "" && this.did != "") {
      this.uoloadSingleStatus = "edit";
    } else {
      this.uoloadSingleStatus = "add";
    }
    // 待审核只能修改医院    隐藏负责区域
    if (this.statue == "正常") {
      this.formStauts = true;
    } else {
      this.formStauts = false;
    }

    console.log(this.uid == "");
  },
  mounted() {
    if (this.uid != "" && this.did != "") {
      this.getInfo();
    }

    if (this.from == "doctorManageD") {
      this.getHisList();
    }
  },
  methods: {
    // changeOption(val) {
    //   console.log(val);
    // },
    //获取医生详情
    getInfo() {
      var db = {
        doctor_info_id: this.did,
      };
      this.$axios.post(DoctorInfo, db).then(
        (res) => {
          if (res.data.code == 1) {
            this.form = res.data.data;
            console.log(res.data.data.position_id);

            //渲染职称
            var arr = [];
            arr = res.data.data.position_id.split(",");
            for (let i = 0; i < arr.length; i++) {
              this.position_id.push(Number(arr[i]));
            }
            this.form.position_id = this.position_id;
            console.log(this.form.position_id, 123);

            
            //渲染所属分类
            var arr1 = [];
            arr1 = res.data.data.classify.split(",");
            for (let i = 0; i < arr1.length; i++) {
              this.classify.push(Number(arr1[i]));
            }
            this.form.classify = this.classify == 0 ? "" : this.classify;
            console.log(this.form.classify, 123);

            this.hospital_name = res.data.data.hospital_name;
            this.fileHead = [{ name: "头像", url: res.data.data.headimg }];
            this.fileZC = res.data.data.position_file;
            this.fileZS = res.data.data.cert_front;
            this.fileCardZ = [
              { name: "身份证正面", url: res.data.data.card_front },
            ];
            this.fileCardF = [
              { name: "身份证反面", url: res.data.data.card_reverse },
            ];
            // console.log(this.form);
          } else {
            this.$message.error(res.data.msg);
          }
        },
        (err) => {}
      );
    },
    //获取职业列表
    getTags() {
      this.$axios.post(PositionList, {}).then(
        (res) => {
          if (res.data.code == 1) {
            this.tags = res.data.data;
            for (let i = 0; i < this.tags.length; i++) {
              if (this.tags[i].position_name === "请选择") {
                this.tags.splice(this.tags[i], 1);
              }
            }
            console.log(this.tags);
          } else {
            this.tags = [];
          }
        },
        (err) => {}
      );
    },
    //获取职业列表
    getHosName() {
      this.$axios.post(HospitalListD, {}).then(
        (res) => {
          if (res.data.code == 1) {
            this.dlist = res.data.data;
          } else {
            this.dlist = [];
          }
        },
        (err) => {}
      );
    },
    //获取负责区域
    getAreaList() {
      this.$axios.post(PermanentAddress, {}).then(
        (res) => {
          if (res.data.code == 1) {
            this.areaList = res.data.data;
          } else {
            this.areaList = [];
          }
        },
        (err) => {}
      );
    },
    //接受子组件传回来的值
    childByValue(val, type) {
      /**
       * val 传回来的值，
       * type 类型
       */
      this.danger = false;
      if (type == 1) {
        //头像
        this.fileHead = [val];
        this.form.headimg = val;
      } else if (type == 2) {
        //医生职称
        this.fileZC = [val];
        this.form.position_file = val;
      } else if (type == 3) {
        //证书
        this.fileZS = [val];
        this.form.cert_front = val;
      } else if (type == 4) {
        //身份证 正面
        this.fileCardZ = [val];
        this.form.card_front = val;
      } else {
        //身份证反面
        this.fileCardF = [val];
        this.form.card_reverse = val;
      }
    },
    //多张图片的子组件返回值
    childByValueMore(childValue, name) {
      console.log(childValue);
      // console.log(childValue)
      if (name == "zc") {
        this.fileZC = childValue;
      }
      if (name == "zs") {
        this.fileZS = childValue;
      }
    },
    submitInfo(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.fileZC.length == 0) {
            this.danger = true;
          }
          // else if (this.form.introduce.length < 30) {
          //   this.$message.error("个人简介字数不能小于30个字");
          // }
          else if (this.form.good_at.length < 10) {
            this.$message.error("擅长描述字数不能小于10个字");
          } else {
            var zc = [];
            if (this.fileZC.length > 0) {
              for (let i = 0; i < this.fileZC.length; i++) {
                if (
                  this.fileZC[i].url != undefined &&
                  this.fileZC[i].url != ""
                ) {
                  zc.push(this.fileZC[i].url);
                } else if (
                  this.fileZC[i] != "" &&
                  this.fileZC[i] != undefined
                ) {
                  zc.push(this.fileZC[i]);
                }
              }
            }
            var zs = [];
            if (this.fileZS.length > 0) {
              for (let i = 0; i < this.fileZS.length; i++) {
                if (
                  this.fileZS[i].url != undefined &&
                  this.fileZS[i].url != ""
                ) {
                  zs.push(this.fileZS[i].url);
                } else if (
                  this.fileZS[i] != "" &&
                  this.fileZS[i] != undefined
                ) {
                  zs.push(this.fileZS[i]);
                }
              }
            }
            this.loading = true;
            this.form.area = this.form.area.join(",");
            this.form.position_id = this.form.position_id.join(",");
            this.form.classify = this.form.classify.join(",");
            this.form.position_file = zc.length > 0 ? zc.join(",") : ""; //职称
            this.form.cert_front = zs.length > 0 ? zs.join(",") : ""; //证书
            console.log(this.form);
            // return;
            if (this.uid == "" && this.did == "") {
              //新增
              var url = AddDoctor;
            } else {
              //编辑
              var url = UpdateDoctorInfo;
              this.form.doctor_info_id = this.did;
              this.form.doctor_user_id = this.uid;
            }
            this.$axios.post(url, this.form).then(
              (res) => {
                if (res.data.code == 1) {
                  this.$message.success(res.data.msg);
                  if (this.uid != "" && this.did != "") {
                    this.isSave = true;
                  }
                  if (this.from == undefined) {
                    this.from = "doctorManageA";
                  }
                  this.$router.push({
                    path: this.from,
                  });
                } else {
                  this.$message.error(res.data.msg);
                }
                this.loading = false;
              },
              (err) => {}
            );
          }
        } else {
          return false;
        }
      });
    },
    //取消退出
    cancelExit() {
      this.$confirm("信息未保存是否退出", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$emit("sureGoback", true);
      });
    },
    /**
     * 待审核事件
     */
    //审核历史列表
    getHisList() {
      this.$axios.post(DoctorCheckList, { doctor_info_id: this.did }).then(
        (res) => {
          if (res.data.code == 1) {
            this.hisList = res.data.data;
          } else {
            this.hisList = [];
          }
        },
        (err) => {}
      );
    },
    checkAgreed(type) {
      if (type == 1) {
        if (this.form.hospital_id != "" && this.form.hospital_id != null) {
          //同意 传参status
          this.checkisAgreed(2);
        } else {
          this.$message.error("请选择所属医院");
        }
      } else {
        //拒绝 显示弹框
        this.refuseContent == "";
        this.dialogVisible = !this.dialogVisible;
      }
    },
    //提交审核不通过事件
    refundTrader() {
      if (this.refuseContent.length == 0) {
        this.$message.error("审核不通过原因不能为空，请填写！");
      } else {
        this.checkisAgreed(3);
      }
    },
    //请求
    checkisAgreed(parms) {
      var zc = [];
      if (this.fileZC.length > 0) {
        for (let i = 0; i < this.fileZC.length; i++) {
          if (this.fileZC[i].url != undefined && this.fileZC[i].url != "") {
            zc.push(this.fileZC[i].url);
          } else if (this.fileZC[i] != "" && this.fileZC[i] != undefined) {
            zc.push(this.fileZC[i]);
          }
        }
      }

      var zs = [];
      if (this.fileZS.length > 0) {
        for (let i = 0; i < this.fileZS.length; i++) {
          if (this.fileZS[i].url != undefined && this.fileZS[i].url != "") {
            zs.push(this.fileZS[i].url);
          } else if (this.fileZS[i] != "" && this.fileZS[i] != undefined) {
            zs.push(this.fileZS[i]);
          }
        }
      }
      var db = {
        hospital_id: this.form.hospital_id,
        doctor_info_id: this.did,
        status: parms,
        message: this.refuseContent,
        hospital_name:
          this.form.hospital_id == null ||
          this.form.hospital_id == "" ||
          this.form.hospital_id == undefined
            ? this.hospital_name
            : this.form.hospital_id,
        headimg: this.form.headimg,
        card_front: this.form.card_front,
        card_reverse: this.form.card_reverse,
        position_file: zc.length > 0 ? zc.join(",") : "", //职称
        cert_front: zs.length > 0 ? zs.join(",") : "", //证书
      };
      this.$axios.post(UpdateHospital, db).then(
        (res) => {
          if (res.data.code == 1) {
            this.$router.push({
              path: this.from,
            });
          } else {
            this.$message.error("操作失败，请重试");
          }
        },
        (err) => {}
      );
    },
    areaChange() {
      //负责区域改变
      var arr = this.form.area;
      let val = arr.find((obj) => obj == "1");
      if (val != undefined) {
        //全选
        var id = [];
        for (var i = 0; i < this.areaList.length; i++) {
          if (this.areaList[i].area_id != 0 && this.areaList[i].area_id != 1) {
            id.push(this.areaList[i].area_id);
          }
        }
        this.form.area = id;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  height: calc(100vh - 230px);
}

.form {
  width: 50%;
}
</style>


